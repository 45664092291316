import { useEffect, useState } from "react";

interface SearchParamOptions {
  readonly: boolean;
}

const getFromUrl = (name: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get(name);
  if (value) {
    try {
      const valueBase64 = atob(value);
      return JSON.parse(valueBase64);
    } catch {
      console.error("Error parsing search param", name, value);
    }
  }
};

const setUpdateSearchParam = (name: string, value: any, readonly: boolean) => {
  const searchParams = new URLSearchParams(window.location.search);
  const vJson = JSON.stringify(value);
  const vBase64 = btoa(vJson);
  if (searchParams.get(name) === vBase64) {
    return;
  }

  searchParams.set(name, vBase64);
  const url = `${window.location.pathname}?${searchParams.toString()}`;

  if (readonly) {
    window.history.replaceState({}, "", url);
  } else {
    window.history.pushState({}, "", url);
  }
};

function useSearchParams<T>(name: string, default_value: T, options?: SearchParamOptions) {
  const [value, setValue] = useState<T>(() => getFromUrl(name) || default_value);

  useEffect(() => {
    const interval = setTimeout(() => {
      setUpdateSearchParam(name, value, options?.readonly === true);
    }, 250);
    return () => clearInterval(interval);
  }, [value]);

  useEffect(() => {
    const onPopState = (_event: PopStateEvent) => {
      const urlState = getFromUrl(name);
      if (options?.readonly) {
        setUpdateSearchParam(name, value, true);
      } else if (urlState) {
        setValue(urlState);
      }
    };
    addEventListener("popstate", onPopState);
    return () => removeEventListener("popstate", onPopState);
  }, [value]);

  return [value, setValue] as const;
}

export { useSearchParams };
