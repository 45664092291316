import { useState, useEffect, useCallback } from "react";
import { folder, monitor, useControls } from "leva";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

interface PingStats {
  remote?: string;
  local?: string;
  rtt?: number;
  crtt?: number;
}

export enum State {
  UNCONNECTED,
  CONNECTING,
  CONNECTED,
}

const channelAtom = atom<Record<string, RTCDataChannel>>({});
const peerAtom = atom<RTCPeerConnection | undefined>(undefined);

export const sendAllowedAtom = atom(false);
export const mediaStreamAtom = atom<MediaStream | undefined>(undefined);

const useWebRTC = () => {
  const [pc, setPc] = useAtom(peerAtom);
  const [ws, setWs] = useState<WebSocket>();

  const [state, setState] = useState<State>(State.UNCONNECTED);

  const [connectionState, setConnectionState] = useState<RTCPeerConnectionState>();
  const [iceConnectionState, setIceConnectionState] = useState<RTCIceConnectionState>();
  const [iceGaterhingState, setIceGaterhingState] = useState<RTCIceGatheringState>();
  const [signalingState, setSignalingState] = useState<RTCSignalingState>();
  const [connectionTime, setConnectionTime] = useState<number>(0);
  const [wsStatus, setWsStatus] = useState<string>("unconnected");
  const [pingStats, setPingStats] = useState<PingStats>({});

  const [channels, setChannels] = useAtom(channelAtom);
  const setMedia = useSetAtom(mediaStreamAtom);

  const { SignalingServer } = useControls(
    "Connection State",
    {
      SignalingServer: {
        value: "wss://sm-signaling.deno.dev",
        render: () => false,
      },
      Status: folder(
        {
          Connection: monitor(() => connectionState ?? "unconnected"),
          Time: monitor(() => `${connectionTime.toFixed(0)}ms`),
          IceState: monitor(() => iceConnectionState ?? "unconnected"),
          IceGathering: monitor(() => iceGaterhingState ?? "unconnected"),
          Signaling: monitor(() => signalingState ?? "unconnected"),
          Websocket: monitor(() => wsStatus),
        },
        { collapsed: true }
      ),
      Ping: folder({
        RTT: monitor(() => `${pingStats.rtt?.toFixed(2) ?? "0"}ms`),
        RTTGraph: monitor(() => pingStats.rtt, { graph: true }),
        CRTT: monitor(() => `${pingStats.crtt?.toFixed(2) ?? "0"}ms`),
        CRTTGraph: monitor(() => pingStats.crtt ?? 0, { graph: true }),
        Remote: monitor(() => pingStats.remote ?? "unconnected"),
        Local: monitor(() => pingStats.local ?? "unconnected"),
      }),
    },
    {
      collapsed: true,
    },
    [connectionState, iceConnectionState, signalingState, iceGaterhingState, wsStatus, pingStats]
  );

  const cleanup = useCallback(() => {
    console.log("cleanup", pc, ws);
    if (pc != null) {
      pc.close();
      setPc(undefined);
    }
    if (ws != null) {
      ws.close();
      setWs(undefined);
    }
  }, [ws, pc, setPc]);

  useEffect(() => {
    if (state === State.UNCONNECTED) {
      cleanup();
    }
  }, [state, cleanup]);

  const createPeer = useCallback(
    (iceServers: RTCIceServer[], signaling_device: string) => {
      setState(State.CONNECTING);
      const start_time = performance.now();

      const peer = new RTCPeerConnection({ iceServers });
      setPc(peer);

      console.log("createPeer", iceServers);

      peer.ontrack = (event) => {
        console.log("ontrack", event);
        setMedia(event.streams[0]);
      };
      peer.addTransceiver("video", { direction: "recvonly" });

      peer.onconnectionstatechange = () => {
        setConnectionState(peer.connectionState);
        if (peer.connectionState === "connected") {
          const Time = performance.now() - start_time;
          setConnectionTime(Time);
          setState(State.CONNECTED);
        } else if (peer.connectionState === "disconnected") {
          setState(State.UNCONNECTED);
        } else if (peer.connectionState === "failed") {
          setState(State.UNCONNECTED);
        }
      };

      peer.oniceconnectionstatechange = () => {
        setIceConnectionState(peer.iceConnectionState);
      };
      peer.onsignalingstatechange = () => {
        setSignalingState(peer.signalingState);
      };
      peer.onicegatheringstatechange = () => {
        setIceGaterhingState(peer.iceGatheringState);
      };
      peer.onnegotiationneeded = () => {
        console.log("onnegotiationneeded");
      };

      peer.onicecandidate = (evt) => {
        console.log("icecandidate", evt.candidate);
        socket.send(
          JSON.stringify({
            id: "candidate",
            candidate: evt.candidate,
          })
        );
      };

      peer.ondatachannel = (evt) => {
        const channel = evt.channel;
        channel.onopen = () => {
          console.log("channel open", channel.label);
          if (channels[channel.label]) {
            console.log("channel already exists", channel);
            return;
          }
          setChannels((channels) => ({
            ...channels,
            [channel.label]: channel,
          }));
        };
        channel.onclose = () => {
          console.log("channel close", channel);
          setChannels((channels) => {
            const { [channel.label]: _, ...rest } = channels;
            return rest;
          });
        };
        channel.onerror = (evt) => {
          console.log("channel error", evt);
          setChannels((channels) => {
            const { [channel.label]: _, ...rest } = channels;
            return rest;
          });
        };
      };

      const socket = new WebSocket(`${SignalingServer}?room=${signaling_device}`);
      setWs(socket);
      socket.onopen = () => {
        setWsStatus("connected");
        socket.send(JSON.stringify({ id: "offer" }));
      };
      socket.onerror = (evt) => {
        setWsStatus("error");
        console.log("socket error", evt);
        setState(State.UNCONNECTED);
      };
      socket.close = (evt) => {
        setWsStatus("closed");
        console.log("socket close", evt);
      };
      socket.onmessage = async (evt) => {
        const j = JSON.parse(evt.data) as {
          id: string;
          sdp?: string;
          type?: string;
          candidate?: RTCIceCandidate;
        };
        if (j?.id === "candidate") {
          console.log("addIceCandidate", j);
          await peer.addIceCandidate(j.candidate);
        } else if (j?.type === "offer") {
          console.log("setRemoteDescription", j);
          await peer.setRemoteDescription(j as RTCSessionDescriptionInit);
          const answer = await peer.createAnswer();
          await peer.setLocalDescription(answer);
          socket.send(
            JSON.stringify({
              id: "answer",
              sdp: answer.sdp,
              type: answer.type,
            })
          );
        }
      };
    },
    [setChannels, setPc, channels, setMedia, SignalingServer]
  );

  useEffect(() => {
    if (pc == null) {
      return;
    }

    const interval = setInterval(async () => {
      const stats = await pc.getStats();
      for (const stat of stats.values()) {
        if (stat.type === "candidate-pair" && stat.state === "succeeded") {
          const sstat = stat as RTCIceCandidatePairStats;

          const cu_rtt = sstat.currentRoundTripTime ?? 0 * 1000;
          const rtt = ((sstat.totalRoundTripTime ?? 0) / (sstat.responsesReceived ?? 0)) * 1000;
          const pingobj: PingStats = {};

          pingobj.rtt = rtt;
          pingobj.crtt = cu_rtt;

          const localCandidate = stats.get(sstat.localCandidateId);
          if (localCandidate) {
            pingobj.local = `${localCandidate.candidateType}:${localCandidate.ip}:${
              localCandidate.networkType ?? ""
            }`;
          }

          const remoteCandidate = stats.get(sstat.remoteCandidateId);
          if (remoteCandidate) {
            pingobj.remote = `${remoteCandidate.candidateType}:${remoteCandidate.ip}:${
              remoteCandidate.networkType ?? ""
            }`;
          }

          setPingStats(pingobj);

          break;
        }
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [pc]);

  return {
    createPeer,
    state,
  };
};

export default useWebRTC;

interface DataChannelSettings {
  forceAllowSend?: boolean;
  create?: boolean;
  dataChannelDict?: RTCDataChannelInit;
}

type DataType = string | Blob | ArrayBuffer | ArrayBufferView;

const useGetChannel = (
  label: string,
  settings?: DataChannelSettings
): RTCDataChannel | undefined => {
  const channels = useAtomValue(channelAtom);

  const pcChannel = channels[label];
  const [channel, setChannel] = useState<RTCDataChannel>();

  useEffect(() => {
    // TODO: create channel
    setChannel(pcChannel);
  }, [pcChannel]);

  return channel;
};

export const useDataChannel = (
  label: string,
  onMessage?: (evt: MessageEvent) => void,
  settings?: DataChannelSettings
) => {
  const channel = useGetChannel(label, settings);
  const sendAllowed = useAtomValue(sendAllowedAtom);
  const sendMessage = useCallback(
    (msg: DataType) => {
      if (channel?.readyState !== "open") {
        return;
      }

      channel.send(msg);
    },
    [channel]
  );

  useEffect(() => {
    if (channel === undefined || onMessage === undefined) {
      return;
    }

    // console.log("useDataChannel addEventListener", channel);

    channel?.addEventListener("message", onMessage);
    return () => {
      channel?.removeEventListener("message", onMessage);
    };
    // onMessage, not included in the dependency array, because it should not change
  }, [channel]);

  return {
    // message,
    sendMessage:
      channel?.readyState !== "open" ||
      (settings?.forceAllowSend === false && sendAllowed === false)
        ? undefined
        : sendMessage,
  };
};

// type AdviceChannel = Record<string, string>;

// export const useAdviceChannel = () => {
//   const [topics, setTopics] = useState<AdviceChannel>({});
//   const [tracks, setTracks] = useState<string[]>([]);

//   const onMessage = useCallback((evt: MessageEvent) => {
//     const advice = JSON.parse(evt.data as string);
//     console.log("advice", advice);
//     setTopics(advice.topics);
//     setTracks(advice.tracks);
//   }, []);

//   useDataChannel("control", onMessage);

//   return {
//     topics,
//     tracks,
//   };
// };
