import React, { useCallback, useEffect, useRef, useState } from "react";
import useWebRTC, { mediaStreamAtom, State, sendAllowedAtom, useDataChannel } from "./use-webrtc";

import ThreeView from "./threeview";
import { button, Leva, monitor, useControls } from "leva";
import { Rnd } from "react-rnd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useSearchParams } from "./use-search-param";

const Service = () => {
  const [sendAllowed, setSendAllowed] = useAtom(sendAllowedAtom);
  const { sendMessage } = useDataChannel("control", (evt: MessageEvent) => {
    const message = evt.data as unknown;
    console.log(typeof message, message);
    if (message && typeof message === "string") {
      if (message === "ok") {
        setSendAllowed(true);
      } else if (message === "viewer") {
        setSendAllowed(false);
      }
    }
  }, {
    forceAllowSend: true,
  });

  const [_, set] = useControls(
    () => ({
      "Viewer": {
        value: !sendAllowed,
        // disable in production
        disabled: !(process.env.NODE_ENV !== 'production' || location.pathname === "/debug"),
        onChange: (v: boolean) => {
          setSendAllowed(!v);
        },
      },
      "Password": {
        value: "",
        render: (get) => get("Viewer") === true,
        onChange: (v: string) => { sendMessage && v && sendMessage(v) },
        disabled: sendMessage === undefined,
      },
    }),
    [sendMessage]
  )

  useEffect(() => {
    set({
      Viewer: !sendAllowed
    })
  }, [sendAllowed]);

  return null;
}

const MediaStream = () => {
  const mediaStream = useAtomValue(mediaStreamAtom);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [tracks, setTracks] = useState<string[]>([]);
  const [showTrack, setShowTrack] = useState(false);

  const { sendMessage: sendTrack } = useDataChannel("track", (evt: MessageEvent) => {
    const message = evt.data as unknown;
    if (message && typeof message === "string") {
      const tracks = JSON.parse(message) as string[];
      setTracks(tracks);
    }
  });
  //   const setMedia = useSetAtom(mediaStreamAtom)

  useControls(
    "Teleop",
    {
      Stream: {
        options: ["none", ...tracks],
        disabled: sendTrack === undefined,
        onChange: (v: string) => {
          if (sendTrack === undefined) return;
          sendTrack(v);
          setShowTrack(v !== "none");
        },
      },
    },
    [sendTrack, tracks]
  );

  useEffect(() => {
    if (videoRef.current != null && mediaStream != null) {
      videoRef.current.srcObject = mediaStream;
    }
  }, [videoRef, mediaStream]);

  if (mediaStream?.active) {
    return (
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: 450,
          height: (450 * 9) / 16,
        }}
        position={{ x: pos.x, y: pos.y }}
        onDragStop={(e, d) => {
          setPos({ x: d.x, y: d.y });
        }}

        bounds="window"
        lockAspectRatio={16 / 9}
        style={{
          background: "white",
          display: showTrack ? "block" : "none",
        }}
      >
        <video style={{ width: "100%" }} ref={videoRef} autoPlay={true} playsInline={true} />
      </Rnd>
    );
  }

  return null;
};

const GamePad = () => {
  const { sendMessage: sendJoy } = useDataChannel("joy");
  const [_, setControl] = useControls(
    "Teleop",
    () => ({
      Input: {
        value: "Unconnected",
        disabled: true,
      },
      Timestamp: {
        value: 0,
        disabled: true,
      },
      Left: {
        value: {
          x: 0,
          y: 0,
        },
        joystick: false,
        disabled: true,
      },
      Right: {
        value: {
          x: 0,
          y: 0,
        },
        joystick: false,
        disabled: true,
      },
    }),
    {
      collapsed: true,
    }, []);


  useEffect(() => {

    let visible = true;

    const onVisibilityChange = () => {
      visible = !document.hidden;
    }

    const interval = setInterval(() => {

      const gamepad = navigator.getGamepads()[0];
      if (gamepad == null || !visible) {
        return
      }

      // deadzone
      const left = [gamepad.axes[0], gamepad.axes[1]];
      if (Math.abs(left[0]) + Math.abs(left[1]) < 0.1) {
        left[0] = 0;
        left[1] = 0;
      }

      const right = [gamepad.axes[2], gamepad.axes[3]];
      if (Math.abs(right[0]) + Math.abs(right[1]) < 0.1) {
        right[0] = 0;
        right[1] = 0;
      }

      setControl({
        Input: gamepad.id,
        Timestamp: gamepad.timestamp,
        Left: {
          x: left[0],
          y: left[1],
        },
        Right: {
          x: right[2],
          y: right[3],
        },
      })

      if (sendJoy == null) {
        return;
      }

      const timestamp = gamepad.timestamp + performance.timeOrigin;
      const sec = Math.floor(timestamp / 1000);
      const nanosec = Math.floor((timestamp - sec * 1000) * 1000000);

      const msg = {
        axes: gamepad.axes,
        buttons: gamepad.buttons.map((b) => b.value),
        sec,
        nanosec,
      };
      sendJoy(JSON.stringify(msg));

    }, 1000 / 10);

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
      clearInterval(interval);
    };
  }, [sendJoy]);



  // useControls(
  //   "Mission",
  //   {
  //     "🚀 START 🚀": button(() => { }),
  //     "🛑 STOP 🛑": button(() => { }),
  //   }
  // )

  return null;
};

const Battery = () => {
  const [pingmsg, setPingMsg] = useState("unknown");
  const { sendMessage: sendPing } = useDataChannel(
    "ping",
    useCallback((evt: MessageEvent) => {
      const message = evt.data as unknown;
      if (message && typeof message === "string") {
        const sendTime = parseInt(message);
        const recvTime = Math.floor(performance.now() * 1000);
        const latency = (recvTime - sendTime) / 2;
        const latencyms = latency / 1000;
        setPingMsg(`${latencyms.toFixed(2)}ms`);
      }
    }, []),
  );

  useEffect(() => {
    if (sendPing === undefined) return;
    console.log("ping interval");
    const interval = setInterval(() => {
      const msg = Math.floor(performance.now() * 1000);
      sendPing(msg.toString());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [sendPing]);


  const [voltage, setVoltage] = useState<number>();
  useDataChannel("voltage", (evt: MessageEvent) => {
    const message = evt.data as unknown;
    if (message && typeof message === "string") {
      setVoltage(parseFloat(message));
    }
  });

  const [velocity, setVelocity] = useState<number>();
  useDataChannel("velocity", (evt: MessageEvent) => {
    const message = evt.data as unknown;
    if (message && typeof message === "string") {
      setVelocity(parseFloat(message));
    }
  });


  useControls(
    "Vehicle",
    {
      Ping: monitor(() => pingmsg ?? "unconnected"),
      // Battery: monitor(() => (voltage ?? 0).toFixed(2)),
      // BatGraph: monitor(() => voltage, { graph: true, interval: 500 }),
      Velocity: monitor(() => `${(velocity ?? 0).toFixed(2)}km/h`),
      VelGraph: monitor(() => velocity, { graph: true, interval: 500 }),
    },
    { collapsed: true },
    [voltage, pingmsg, velocity]
  );


  return null;
};

const App = () => {
  const { createPeer, state } = useWebRTC();
  const disabled = state !== State.UNCONNECTED;

  const [signalDevice, setSignalDevice] = useSearchParams("url", "orin", { readonly: true });

  // Cant use folders, https://github.com/pmndrs/leva/issues/345
  useControls(
    {
      STATE: monitor(() => State[state]),
      url: {
        label: "RoomID",
        value: signalDevice,
        disabled,
        onChange: (v) => {
          setSignalDevice(v);
        },
      },
      Connect: button(
        (get) => {
          const url = get("url") as string;
          const turn: RTCIceServer[] = [
            {
              urls: "stun:158.101.172.190:3478",
            },
            {
              urls: "turn:158.101.172.190:3478",
              username: "sensuser",
              credential: "passmore52969",
            }
          ];

          createPeer(turn, url);
        },
        { disabled }
      ),
    },
    [disabled, createPeer, state, setSignalDevice, signalDevice]
  );

  const setSendAllowed = useSetAtom(sendAllowedAtom);
  useEffect(() => {
    if (state != State.CONNECTED) {
      setSendAllowed(false);
    }
  }, [state]);


  const title = `TruckOS - ${State[state]} - ${signalDevice}`;


  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Leva
        titleBar={{
          title,
        }}
      />
      <Service />

      <GamePad />
      <Battery />
      <ThreeView />
      <MediaStream />
    </>
  );
};

export default App;
