import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import {
  Grid,
  Line,
  MapControls,
  Plane,
  PointMaterial,
  Select,
  Sphere,
  TransformControls,
  useCursor,
} from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader.js";
import { Group, type Object3D } from "three";
import { button, monitor, useControls } from "leva";
import { sendAllowedAtom, useDataChannel } from "./use-webrtc";
import { ErrorBoundary } from "react-error-boundary";
import { useSearchParams } from "./use-search-param";
import { useAtomValue } from "jotai";

const Waypoint = () => {
  const [points, setPoints] = useSearchParams<Array<[number, [number, number, number]]>>("waypoints", [
    [0, [-1, 0, 0]],
    [1, [3, 0, 0]],
  ]);

  const [pointID, setPointID] = React.useState<number>(points.length);
  const [hovered, setHover] = useState(false);
  useCursor(hovered);

  const [selected, setSelected] = React.useState<Object3D[]>([]);
  const active = selected.length == 1 ? selected[0] : null;
  const cur_idx = points.findIndex((p) => p[0] === active?.userData.id);

  const { sendMessage } = useDataChannel("path");
  const { sendMessage: sendEvent } = useDataChannel("event");

  const [targetIndex, setTargetIndex] = useState(-1);

  const sendAllowed = useAtomValue(sendAllowedAtom);

  useDataChannel("target_index", (evt: MessageEvent) => {
    // console.log("received target index: ", evt.data);
    const message = evt.data as unknown;
    if (message && typeof message === "string") {
      setTargetIndex(parseInt(message));
    }
  });

  useControls(
    "Path Control",
    {
      "Event": button(
        () => {
          const event_name = prompt("Event Name");
          if (event_name) {
            sendEvent && sendEvent(event_name);
          }
        },
        { disabled: sendEvent === undefined }
      ),
      "Add Point": button(() => {
        const last_pos = points[points.length - 1][1];
        setPoints((p) => [...p, [pointID, [last_pos[0] + 1, last_pos[1], last_pos[2]]]]);
        setPointID(pointID + 1);
      }),
      "Delete Selected ": button(
        () => {
          const delete_idx = selected.map((s) => s.userData.id);
          setPoints((p) => p.filter((p) => !delete_idx.includes(p[0])));
          setSelected([]);
        },
        { disabled: selected.length == 0 }
      ),
      "Move Point Up": button(
        () => {
          const new_idx = cur_idx + 1;
          if (new_idx >= points.length) {
            return;
          }
          // move point up
          setPoints((p) => {
            const tmp = p[cur_idx];
            p[cur_idx] = p[new_idx];
            p[new_idx] = tmp;
            return [...p];
          });
        },
        { disabled: !active || cur_idx === points.length - 1 }
      ),
      "Move Point Down": button(
        () => {
          const new_idx = cur_idx - 1;
          if (new_idx < 0) {
            return;
          }
          // move point down
          setPoints((p) => {
            const tmp = p[cur_idx];
            p[cur_idx] = p[new_idx];
            p[new_idx] = tmp;
            return [...p];
          });
        },
        { disabled: !active || cur_idx === 0 }
      ),
      "Send Path": button(
        () => {
          const p = points.map((p) => p[1]);
          if (sendMessage) {
            sendMessage(JSON.stringify(p));
          }
        },
        { disabled: sendMessage === undefined }
      ),
      "Reset Path": button(() => {
        const ok = window.confirm("Are you sure you want to reset the path?");
        if (!ok) {
          return;
        }
        setPoints([
          [0, [-1, 0, 0]],
          [1, [3, 0, 0]],
        ]);
      }),
    },
    {
      render: (get) => get("Viewer") === false,
    },
    [selected, points, active, cur_idx, sendMessage, sendEvent]
  );

  const MovePoint = useCallback(() => {
    if (!active) {
      return;
    }
    const current = active.userData.id as number;
    const idx = points.findIndex((p) => p[0] === current);
    const pos = active.position;
    if (idx === -1) {
      return;
    }
    // idx exists
    setPoints((p) => {
      if (idx < p.length) {
        p[idx][1] = [pos.x, pos.y, pos.z];
      }
      return [...p];
    });
  }, [active, points]);

  const size = 0.15;

  return (
    <>
      {active && sendAllowed && (
        <TransformControls onObjectChange={MovePoint} object={active} showY={false} />
      )}

      {sendAllowed && (
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <Plane
            args={[2000, 2000]}
            onDoubleClick={(e) => {
              e.stopPropagation();
              const pos = e.point;
              setPoints((p) => [...p, [pointID, [pos.x, pos.y, pos.z]]]);
              setPointID(pointID + 1);
            }}
            visible={false}
          />
        </group>
      )}

      <Select
        // TODO: multiple selection
        // multiple={true}
        // box={true}
        // onChange={(e) => {
        //   console.log('change', e);
        // }}

        onChangePointerUp={setSelected}
        // sanity check
        filter={e => e.filter((o) => o.userData.id !== undefined)}
      >
        {points.map(([i, pos]) => (
          <Sphere
            key={i}
            args={[targetIndex === i ? 3 * size : size]}
            position={pos}
            userData={{ id: i }}
            onPointerOver={() => {
              setHover(true);
            }}
            onPointerOut={() => {
              setHover(false);
            }}
          >
            {
              // generate color from i
              <meshStandardMaterial
                color={`hsl(${(i * 360) / 10}, 100%, ${selected.findIndex((s) => s.userData.id === i) !== -1 ? 80 : 50}%)`}
              />
            }
            {/* <Html distanceFactor={10} >
                            <div className="content">
                                <h1>{i}</h1>
                            </div>
                        </Html> */}
          </Sphere>
        ))}
      </Select>

      {/* drawing lines between points */}
      {points.length >= 2 && <Line
        points={points.map((p) => p[1])}
        color="white"
        lineWidth={size * 1.5}
        dashed={false}
        worldUnits={true}
      />}
    </>
  );
};

interface TransformTranslate {
  x: number;
  y: number;
  z: number;
}

interface TransformRotation {
  x: number;
  y: number;
  z: number;
  w: number;
}

interface Transform {
  translation: TransformTranslate;
  rotation: TransformRotation;
}

const Ros2Three = (vec3: TransformTranslate): [number, number, number] => {
  return [vec3.x, vec3.y, vec3.z];
};

const Quaternion2Euler = (q: TransformRotation): [number, number, number] => {
  const { x, y, z, w } = q;
  const ysqr = y * y;

  // roll (x-axis rotation)
  const t0 = +2.0 * (w * x + y * z);
  const t1 = +1.0 - 2.0 * (x * x + ysqr);
  const roll = Math.atan2(t0, t1);

  // pitch (y-axis rotation)
  let t2 = +2.0 * (w * y - z * x);
  t2 > 1.0 ? (t2 = 1.0) : t2 < -1.0 ? (t2 = -1.0) : t2;
  const pitch = Math.asin(t2);

  // yaw (z-axis rotation)
  const t3 = +2.0 * (w * z + x * y);
  const t4 = +1.0 - 2.0 * (ysqr + z * z);
  const yaw = Math.atan2(t3, t4);

  return [roll, pitch, yaw + Math.PI / 2];
};

const Deg2Rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};

interface CloudProps {
  uri: string;
  color: string;
  size?: number;
  opacity?: number;
}

const Cloud: React.FC<CloudProps> = ({ uri, color, size = 0.01, opacity = 0.75 }) => {
  const pcds = useLoader(PCDLoader, uri);
  const colorProp = pcds?.material?.vertexColors ? undefined : color
  const vectex = pcds?.material?.vertexColors === true

  return (
    pcds && (
      <primitive object={pcds}>
        <PointMaterial
          // key required to force reset the material
          key={+vectex}
          transparent={true}
          opacity={opacity}
          vertexColors={vectex}
          color={colorProp}
          size={size}
          depthWrite={false}
        />
      </primitive>
    )
  );
};

const Loader = () => {
  const [tf, setTf] = useState<Transform>();
  useDataChannel("tf", (evt) => {
    const transform = JSON.parse(evt.data) as Transform;
    // console.log("TF", evt.data)
    setTf(transform);
  });

  const [steering, setSteering] = useState<number>(0);
  useDataChannel("steering", (evt) => {
    const steering = parseFloat(evt.data);
    setSteering(steering);
  });


  const [_, setVehicle] = useControls(
    "Vehicle",
    () => ({
      Pos: {
        value: {
          x: 0,
          y: 0,
          z: 0,
        },
        disabled: true,
      },
      Steering: monitor(() => steering.toFixed(2)),
      SteerGraph: monitor(() => steering, { graph: true }),
    }),
    { collapsed: true },
    [steering]
  );

  useEffect(() => {
    if (tf === undefined) {
      return;
    }
    setVehicle({
      Pos: tf.translation,
    });
  }, [tf]);

  const loaderBack = useLoader(STLLoader, "/assets/pfl20-back.stl");
  const loaderFront = useLoader(STLLoader, "/assets/pfl20-front.stl");

  if (tf === undefined) {
    return null;
  }

  return (
    <group
      rotation={[0, 0, -(Math.PI / 2)]}
      scale={[0.001, 0.001, 0.001]}
      position={Ros2Three(tf.translation)}
    >
      <group rotation={Quaternion2Euler(tf.rotation)}>
        <mesh rotation={[0, 0, -Deg2Rad(steering / 2)]}>
          <meshStandardMaterial color={"gray"} />
          <primitive object={loaderBack} />
        </mesh>
        <mesh rotation={[0, 0, Deg2Rad(steering / 2)]}>
          <primitive object={loaderFront} />
          <meshStandardMaterial color={"gray"} />
        </mesh>
      </group>
    </group>
  );
};

const ThreeView = () => {
  const controlRef = useRef<MapControls>();
  const [uri, setUri] = useState<string>()
  // useControls("Camera", {
  //   Reset: button(() => {
  //     controlRef.current?.reset();
  //   }),
  //   FocusPoint: button(() => {
  //     const cam = controlRef.current;
  //     if (cam) {
  //       cam.target.set(0, 0, 0);
  //     }
  //   }),
  // });

  const { Points, Opacity, PointSize, Background, ShowGrid, SectionSize, CellSize } = useControls(
    "Colors",
    {
      Points: "#F9F7F4",
      Opacity: {
        value: 0.75,
        min: 0,
        max: 1,
      },
      PointSize: {
        value: 10,
        min: 1,
        max: 250,
      },
      Background: "#0C0032",
      ShowGrid: true,
      SectionSize: {
        value: 10,
        min: 1,
        max: 100,
      },
      CellSize: {
        value: 10,
        min: 1,
        max: 100,
      },
    },
    { collapsed: true }
  );

  const onDrop = useCallback((ev: DragEvent) => {
    ev.preventDefault()
    let files: File[] | undefined;
    if (ev.dataTransfer?.items) {
      files = [...ev.dataTransfer.items]
        .filter(i => i.kind === "file")
        .map(i => i.getAsFile())
        .filter((f): f is File => f !== null);
    } else if (ev.dataTransfer) {
      files = [...ev.dataTransfer.files];
    }

    if (files) {
      const file = files[0]
      const uri = URL.createObjectURL(file)
      setUri(uri)
    }
  }, [])

  const onDragOver = useCallback((ev: DragEvent) => {
    // required to get the onDrop event working
    ev.preventDefault();

  }, [])


  return (
    // top down view
    <Canvas
      // orthographic
      camera={{
        position: [0, 10, 0],
        far: 70000,
      }}
      style={{
        background: Background,
      }}

      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <ambientLight intensity={0.2} />
      <directionalLight intensity={0.5} position={[80, 80, 30]} castShadow />

      {
        ShowGrid && <Grid
          infiniteGrid={true}
          cellColor="white"
          sectionSize={SectionSize}
          cellSize={CellSize}
          fadeDistance={1000}
          fadeStrength={2}
        />
      }
      <MapControls makeDefault ref={controlRef} />
      <Waypoint />

      <group rotation={[-Math.PI / 2, 0, 0]}>
        <Suspense fallback={null}>
          {/* TODO: Kinda ugly hack */}
          <ErrorBoundary fallback={<mesh />}>
            {
              uri && <Cloud uri={uri} color={Points} opacity={Opacity} size={PointSize / 1000} />
            }
          </ErrorBoundary>
          <ErrorBoundary fallback={<mesh />}>
            <Loader />
          </ErrorBoundary>
        </Suspense>

      </group>
    </Canvas>
  );
};

export default ThreeView;
